import React, { useContext } from 'react';
import Product from './Product';

import { ClientContext } from '../../context/ClientContext'
import { StoreContext } from '../../context/Store'

function Products(props){
  const { store, dispatch } = useContext(StoreContext)
  const { store: { client} } = useContext(ClientContext);

  function addVariantToCart(variantId, quantity){
    const lineItemsToAdd = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
        customAttributes: [{ key: "Name", value: "Justiun" }],
      },
    ];
    const checkoutId = store.checkout.id

    return client.checkout.addLineItems(checkoutId, lineItemsToAdd).then(response => {
      dispatch({type: 'setCheckout', payload: response })
    });
  }

  return(
    <div className="Product-wrapper">
      {store.products.map((product) => {
        return (
          <Product
            addVariantToCart={addVariantToCart}
            client={client}
            key={product.id.toString()}
            product={product}
            />
        );
      })}
    </div>
  );
}
export default Products;
