import React from 'react'

import Layout from '../components/Layout'
import Products from '../components/shopify/Products'

function ProductsPage(props) {
  return (
    <Layout>
      <section
        className="section section--gradient"
        style={{
          color: 'black'
        }}
      >
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <h2 className="is-size-3 has-text-weight-bold is-bold-light">The Menu</h2>
              <Products />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ProductsPage
